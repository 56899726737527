<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Theoretically, what volume of oxygen gas at
        <number-value :value="temp" unit="^{\circ}\text{C}" />
        and
        <number-value :value="pressure" unit="\text{atm}" />
        is produced by the decomposition of
        <number-value :value="mass" unit="\text{g}" />
        of
        <chemical-latex content="KClO3?" />
        The unbalanced reaction is:
      </p>

      <p class="pl-6 mb-5">
        <chemical-latex content="KClO3 -> KCl + O2" />
      </p>

      <p class="mb-2"><b>Hint.</b> You may want to balance the above equation first.</p>

      <calculation-input
        v-model="inputs.studentAnswer"
        prepend-text="$\text{Volume:}$"
        append-text="$\text{mL}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'OleMiss116Post2_Q2part3',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
    };
  },
  computed: {
    temp() {
      return this.taskState.getValueBySymbol('temp').content;
    },
    pressure() {
      return this.taskState.getValueBySymbol('pressure').content;
    },
    mass() {
      return this.taskState.getValueBySymbol('mass').content;
    },
  },
};
</script>
